<template>
  <div class="favourite-button">
    <ZButton
      :outline="hasLabel"
      :variant="!hasLabel ? 'white' : undefined"
      :icon="!hasLabel"
      :size="hasLabel ? 'md' : iconButtonSize"
      @click.stop.prevent="handleClick"
    >
      <fa :icon="isFavourited ? ['fas', 'heart'] : ['far', 'heart']" /><span
        v-if="hasLabel"
        class="ml-2"
      >{{
        $t('actions.save')
      }}</span>
    </ZButton>

    <ZModal
      v-if="hasMultipleCollections"
      :visible="showModal"
      :title="t('selectCollection')"
      hide-footer
      padded
      @hide="handleHideModal"
      @hidden="newCollection = false"
    >
      <FavouritesCollectionForm
        v-if="newCollection"
        :rv-id="rvId"
        @collection-created="showModal = false"
        @collection-reset="newCollection = false"
      />
      <ZListGroup v-else>
        <ZListGroupItem
          v-for="collection in collections"
          :key="collection.Id"
          button
          class="d-flex justify-content-between align-items-center"
          @click="addToCollection(collection.Id)"
        >
          <span>
            <fa
              :icon="['far', 'heart']"
              class="mr-2"
            />
            {{ collection.CollectionName === 'Favourites' ? t('favourites') : collection.CollectionName }}
          </span>
          <span
            v-if="collection.Items"
            class="badge badge-highlight"
          >{{ collection.Items.length }}</span>
        </ZListGroupItem>

        <ZListGroupItem
          button
          @click="createNewCollection"
        >
          <fa
            :icon="['fas', 'plus']"
            class="mr-2"
          />
          {{ t('favourites.createNewList') }}
        </ZListGroupItem>
      </ZListGroup>
    </ZModal>
  </div>
</template>

<script setup lang="ts">
import { messageAppToSignup } from '~/lib/mobileMessages/messageAppToSignup'

import type { ButtonSize } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  rvId: string
  hasLabel?: boolean
  iconButtonSize?: ButtonSize
}>(), {
  iconButtonSize: 'md',
})

const { $captureError } = useNuxtApp()
const { t } = useI18n()
const { isLoggedIn } = useAuthentication()
const { isWebView } = usePlatform()
const { showLogin } = useLogin()
const { collections, addRvToCollection, removeRvFromCollection, isRvFavourited } = useFavouriteRVs()

const showModal = ref(false)
const newCollection = ref(false)
const isLoading = ref(false)

const hasMultipleCollections = computed(() => collections.value.length > 1)
const isFavourited = computed(() => isRvFavourited(props.rvId))

async function handleClick() {
  const collectionId = collections.value.find((collection) => collection.CollectionName === 'Favourites')?.Id

  if (!isLoggedIn.value) {
    if (isWebView.value) {
      const cookiesJSON = {
        [props.rvId]: useCookie(props.rvId).value,
      }
      const appMessage = {
        successUrl: window.location.href,
        failureUrl: window.location.href,
        cookies: cookiesJSON,
      }
      messageAppToSignup(appMessage)
    }
    else {
      showLogin()
    }
  }
  else if (isFavourited.value && collectionId) {
    try {
      isLoading.value = true

      await removeRvFromCollection({
        RvId: Number(props.rvId),
        CollectionId: collectionId,
      })
    }
    catch (_error) {
      $captureError(new Error('Error removing RV from collection'))
    }
    finally {
      isLoading.value = false
    }
  }
  else if (hasMultipleCollections.value) {
    showModal.value = true
  }
  else {
    try {
      isLoading.value = true

      await addRvToCollection({ rvID: props.rvId })
    }
    catch (_error) {
      $captureError(new Error('Error add RV to collection'))
    }
    finally {
      isLoading.value = false
    }
  }
}

async function addToCollection(collectionID?: number) {
  if (!collectionID) {
    return
  }

  await addRvToCollection({ rvID: props.rvId, collectionID })
  showModal.value = false
}

async function createNewCollection() {
  newCollection.value = true
}

async function handleHideModal() {
  showModal.value = false
}
</script>

<i18n lang="json">
{
  "en": {
    "favourites": "Favorites",
    "selectCollection": "Select a Favorites List"
  },
  "fr": {
    "favourites": "Favoris",
    "selectCollection": "Choisissez une liste de favoris"
  }
}
</i18n>
